import { Flex, Tabs, Text } from "@mantine/core";
import { useEffect, useState } from "react";

import {
  ClaseCard,
  CursoCarousel,
  EducacionTabs,
  GenderLabAppShell,
  GenderLabBreadcrumbs,
  GenderLabLoader,
} from "../../components";
import { aulaService } from "../../api/";
import { useAuth } from "../../context";
import { useTranslation } from "react-i18next";
import { TInstructionPeriod } from "../../types/Aula/TAulaInstructionPeriod";

export const EducacionPage = () => {
  const { t } = useTranslation();
  const [availableInstructionPeriods, setAvailableInstructionPeriods] = useState<TInstructionPeriod[]>(null!);
  const [activeInstructionPeriods, setActiveInstructionPeriods] = useState<TInstructionPeriod[]>(null!);
  const [expiredInstructionPeriods, setExpiredInstructionPeriods] = useState<TInstructionPeriod[]>(null!);

  const { user } = useAuth();

  useEffect(() => {
    const fetchAulaCourses = async () => {
      if (user) {
        try {
          const instructionPeriodsWithCoursesResponse =
            await aulaService.getActiveAndAvailableNewCourses();
          setAvailableInstructionPeriods(
            instructionPeriodsWithCoursesResponse.data.available_courses
          );
          setExpiredInstructionPeriods(
            instructionPeriodsWithCoursesResponse.data.expired_courses
          );

          if (user.hasAULA || user.hasExpiredAULA) {
            setActiveInstructionPeriods(
              instructionPeriodsWithCoursesResponse.data.active_courses
            );
          } else {
            setActiveInstructionPeriods([]);
          }
        } catch {
          setAvailableInstructionPeriods([]);
          setActiveInstructionPeriods([]);
        }
      }
    };

    fetchAulaCourses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const extractYear = (date: string) => {
    return date.split("-")[0];
  };

  return (
    <GenderLabAppShell>
      <GenderLabBreadcrumbs
        breadcrumbs={[{ link: "", title: t("appShell.virtualClassroom") }]}
      />
      <EducacionTabs defaultValue="disponibles">
        <Tabs.List>
          <Tabs.Tab value="disponibles">{t("education.available")}</Tabs.Tab>
          <Tabs.Tab value="completados">{t("education.completed")}</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="disponibles" pt="sm">
          {availableInstructionPeriods && activeInstructionPeriods && user ? (
            <>
              {activeInstructionPeriods.map((instructionPeriod, idx) => (
                <Flex direction="column" gap="lg" mt="md" key={idx}>
                  <ClaseCard
                    aulaVirtual
                    title={instructionPeriod.course.name}
                    year={extractYear(instructionPeriod.course.release_date)}
                    description={instructionPeriod.course.description}
                    img={instructionPeriod.course.cover_image}
                    type="purchased"
                    syllabus={instructionPeriod.course.syllabus}
                    courseId={instructionPeriod.course.id}
                    instructionPeriodId={instructionPeriod.id}
                    startDate={instructionPeriod.start_date}
                    endDate={instructionPeriod.end_date}
                  />
                </Flex>
              ))}
              {availableInstructionPeriods.map((instructionPeriod, idx) => (
                <Flex direction="column" gap="lg" mt="md" key={idx}>
                  <ClaseCard
                    aulaVirtual
                    title={instructionPeriod.course.name}
                    year={extractYear(instructionPeriod.course.release_date)}
                    description={instructionPeriod.course.description}
                    img={instructionPeriod.course.cover_image}
                    type="available"
                    syllabus={instructionPeriod.course.syllabus}
                    startDate={instructionPeriod.start_date}
                    endDate={instructionPeriod.end_date}
                  />
                </Flex>
              ))}
              {expiredInstructionPeriods.map((instructionPeriod, idx) => (
                <Flex direction="column" gap="lg" mt="md" key={idx}>
                  <ClaseCard
                    aulaVirtual
                    title={instructionPeriod.course.name}
                    year={extractYear(instructionPeriod.course.release_date)}
                    description={instructionPeriod.course.description}
                    img={instructionPeriod.course.cover_image}
                    type="expired"
                    syllabus={instructionPeriod.course.syllabus}
                    startDate={instructionPeriod.start_date}
                    endDate={instructionPeriod.end_date}
                    courseId={instructionPeriod.course.id}
                    instructionPeriodId={instructionPeriod.id}
                  />
                </Flex>
              ))}
              {activeInstructionPeriods.length === 0 && availableInstructionPeriods.length === 0 && (
                <Text color="dimmed">
                  {t("noCourses")}
                </Text>
              )}
            </>
          ) : (
            <GenderLabLoader />
          )}
        </Tabs.Panel>

        <Tabs.Panel value="completados" pt="sm">
          <CursoCarousel courses={[]} type="inactive" />
        </Tabs.Panel>
      </EducacionTabs>
    </GenderLabAppShell>
  );
};
