import {
  Button,
  Container,
  Flex,
  Group,
  Image,
  Loader,
  PasswordInput,
  Stack,
  Stepper,
  Text,
  TextInput,
  Title,
  useMantineTheme,
} from "@mantine/core";
import { useEffect, useRef, useState } from "react";
import { useForm } from "@mantine/form";
import { useParams } from "react-router-dom";

import { ReactComponent as LogoElsa } from "../../static/svg/logo-elsa-bold.svg";
import { registerLinkService } from "../../api/services";
import { useMediaQuery } from "@mantine/hooks";
import { TRegisterCourseData } from "../../types";
import { GenderLabLoader } from "../../components";
import { validateStringField } from "../../utils";

export const LinkRegistroPage = () => {
  const theme = useMantineTheme();
  const { token } = useParams();
  const emailRef = useRef<HTMLInputElement>(null);
  const nameRef = useRef<HTMLInputElement>(null);
  const matches = useMediaQuery("(min-width: 768px)");

  const [active, setActive] = useState(0);
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [registerCourseData, setRegisterCourseData] =
    useState<TRegisterCourseData | null>(null);

  const formWidth = 300;

  const form = useForm({
    initialValues: {
      email: "",
      first_name: "",
      last_name: "",
      job: "",
      area: "",
      workplace: "",
      document_id: "",
      password1: "",
      password2: "",
    },

    validate: {
      email: (value) =>
        /^\S+@\S+$/.test(value) ? null : "Ingrese un email válido",
      first_name: validateStringField,
      last_name: validateStringField,
      password1: (value) => (value.length > 6 ? null : "Contraseña muy corta"),
      password2: (value, values) =>
        value === values.password1 ? null : "Las contraseñas no coinciden",
    },
  });

  useEffect(() => {
    const getInfo = async () => {
      if (token) {
        try {
          const response = await registerLinkService.getNewInfo(token);
          setRegisterCourseData(response.data);
        } catch (error: any) {}
      }
    };

    getInfo();
  }, [token]);

  const handleNextStep = async () => {
    if (token && registerCourseData) {
      const current = active;
      if (current === 0) {
        console.log(registerCourseData);
        // email
        const error = form.validateField("email").error;
        if (!error) {
          try {
            setLoading(true);
            await registerLinkService.getNewStudent(form.values.email);
            try {
              const response = await registerLinkService.newEnrollExistingStudent({
                course_id: registerCourseData.course.id,
                instruction_period_id: registerCourseData.instruction_period_id,
                email: form.values.email,
              });
              setResponseMessage(response.data.message);
              setActive(2);
            } catch (error: any) {}
          } catch (error: any) {
            if (error.response.status === 404) {
              if (nameRef.current) nameRef.current.focus();
              setActive(1);
            } else {
              setResponseMessage("Error desconocido.");
              setActive(2);
            }
          } finally {
            setLoading(false);
          }
        }
      } else if (current === 1) {
        // data and password
        if (!form.validate().hasErrors) {
          try {
            setLoading(true);
            const response = await registerLinkService.enrollNewStudent({
              first_name: form.values.first_name,
              last_name: form.values.last_name,
              email: form.values.email,
              job: form.values.job,
              area: form.values.area,
              workplace: form.values.workplace,
              document_id: form.values.document_id,
              course_id: registerCourseData.course.id,
              instruction_period_id: registerCourseData.instruction_period_id,
              client_id: registerCourseData.client.id,
              password: form.values.password1,
            });
            setResponseMessage(response.data.message);
            setActive(2);
          } catch (error: any) {
            setResponseMessage("Error desconocido.");
          } finally {
            setLoading(false);
          }
        }
      }
    }
  };

  useEffect(() => {
    if (emailRef.current) {
      emailRef.current.focus();
    }
  }, []);

  const buttonContent = () => (loading ? <Loader size="xs" /> : "Siguiente");

  if (!registerCourseData) {
    return <GenderLabLoader />;
  }

  return (
    <Container size="sm">
      <Flex justify="space-around" my="xl">
        <LogoElsa fill={theme.colors["genderlab-red"][4]} width={200} />
        <Image width={100} src={registerCourseData.client.logo} />
      </Flex>

      <Stack spacing="md" align="center" my="xl">
        <Title order={1} align="center" color="genderlab-red.6">
          Registro a Aula
        </Title>
        <Text align="center" size="md">
          Registro al curso{" "}
          <strong>
            <i>{registerCourseData.course.name}</i>
          </strong>{" "}
          como parte de la empresa{" "}
          <strong>
            <i>{registerCourseData.client.trade_name}</i>
          </strong>
          .
        </Text>
      </Stack>

      <Group position="center">
        <Stepper
          active={active}
          orientation={matches ? "horizontal" : "vertical"}
        >
          <Stepper.Step label="Ingresar correo">
            <form onSubmit={(e) => e.preventDefault()}>
              <Stack spacing="md" align="center">
                <TextInput
                  type="email"
                  label="Email"
                  placeholder="Ingrese su email"
                  w={formWidth}
                  withAsterisk
                  {...form.getInputProps("email")}
                  ref={emailRef}
                />
                <Button
                  onClick={handleNextStep}
                  type="submit"
                  disabled={loading}
                >
                  {buttonContent()}
                </Button>
              </Stack>
            </form>
          </Stepper.Step>
          <Stepper.Step label="Datos y contraseña">
            <form onSubmit={(e) => e.preventDefault()}>
              <Stack spacing="md" align="center">
                <TextInput
                  label="Nombre"
                  placeholder="Ingrese su nombre"
                  w={formWidth}
                  withAsterisk
                  {...form.getInputProps("first_name")}
                />
                <TextInput
                  label="Apellido"
                  placeholder="Ingrese su apellido"
                  w={formWidth}
                  withAsterisk
                  {...form.getInputProps("last_name")}
                />
                <TextInput
                  label="Puesto"
                  placeholder="Ingrese su puesto"
                  w={formWidth}
                  {...form.getInputProps("job")}
                />
                <TextInput
                  label="Área"
                  placeholder="Ingrese su área"
                  w={formWidth}
                  {...form.getInputProps("area")}
                />
                <TextInput
                  label="Centro"
                  placeholder="Ingrese su centro"
                  w={formWidth}
                  {...form.getInputProps("workplace")}
                />
                <TextInput
                  label="Documento"
                  placeholder="Ingrese su documento"
                  w={formWidth}
                  {...form.getInputProps("document_id")}
                />
                <PasswordInput
                  label="Contraseña"
                  placeholder="Ingrese su contraseña"
                  w={formWidth}
                  withAsterisk
                  {...form.getInputProps("password1")}
                />
                <PasswordInput
                  label="Repita su contraseña"
                  placeholder="Repita su contraseña"
                  w={formWidth}
                  withAsterisk
                  {...form.getInputProps("password2")}
                />
                <Button
                  onClick={handleNextStep}
                  type="submit"
                  disabled={loading}
                >
                  {buttonContent()}
                </Button>
              </Stack>
            </form>
          </Stepper.Step>
          <Stepper.Step label="Aula">
            <Stack spacing="md" align="center">
              <Text align="center" size="md">
                {responseMessage}
              </Text>
              <Button
                onClick={() =>
                  (window.location.href =
                    "https://sso.teachable.com/secure/1155395/identity/login/password")
                }
              >
                Ir a Aula
              </Button>
            </Stack>
          </Stepper.Step>
        </Stepper>
      </Group>
    </Container>
  );
};
