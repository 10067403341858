import {
  TEnrollExistingStudentService,
  TEnrollNewStudentService,
} from "../../types";
import { ApiWithToken, ApiWithoutToken } from "../axios-instance";

export const registerLinkService = {
  getTeachableStudent: async (email: string) =>
    ApiWithoutToken.get(`/api/aula/get_teachable_student/${email}/`),
  getNewStudent: async (email: string) =>
    ApiWithoutToken.get(`/api/aula/get_new_student/${email}/`),
  getInfo: async (token: string) =>
    ApiWithoutToken.get("/api/aula/info_unique_url/", {
      params: {
        token,
      },
    }),
  getNewInfo: async (token: string) =>
    ApiWithoutToken.get("/api/aula/new_info_unique_url/", {
      params: {
        token,
      },
    }),
  enrollNewStudent: async (data: TEnrollNewStudentService) =>
    ApiWithoutToken.post("/api/aula/enroll_new_student/", data),
  enrollExistingStudent: async (data: TEnrollExistingStudentService) =>
    ApiWithoutToken.post("/api/aula/enroll_existing_student/", data),
  newEnrollExistingStudent: async (data: TEnrollExistingStudentService) =>
    ApiWithoutToken.post("/api/aula/new_enroll_existing_student/", data),
  getUniqueURLToken: async (course: string, instructionPeriod: string) =>
    ApiWithToken.get("/api/aula/get_unique_url_token/", {
      params: {
        instruction_period: instructionPeriod,
        course,
      },
    }),
};
