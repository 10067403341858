import { ApiWithToken } from "../axios-instance";

export const aulaService = {
  uri: "/api/aula/",
  getActiveCourses: async () =>
    ApiWithToken.get(`${aulaService.uri}active_courses/`),
  getAvailableCourses: async () =>
    ApiWithToken.get(`${aulaService.uri}available_courses/`),
  getActiveAndAvailableCourses: async () =>
    ApiWithToken.get(`${aulaService.uri}active_and_available_courses/`),
  getActiveAndAvailableNewCourses: async () =>
    ApiWithToken.get(`${aulaService.uri}active_and_available_new_courses/`),
  getActiveCourseById: async (id: string) =>
    ApiWithToken.get(`${aulaService.uri}progress/?course=${id}`),
  getActiveNewCourseById: async (courseId: string, instructionPeriodId: string) =>
    ApiWithToken.get(`${aulaService.uri}new_progress/`, {
      params: { course: courseId, instruction_period: instructionPeriodId },
    }),
  getStudentExcelByCourseId: async (id: number) =>
    ApiWithToken.get(`${aulaService.uri}enrollments/download/?course=${id}`,{
      responseType: "blob",
    }),
  getEnrollmentsByCourseId: async (
    id: number,
    page: number,
    pageSize: number,
    query?: string,
    sortField?: string,
    sortOrder?: string
  ) => {
    return ApiWithToken.get(aulaService.uri + "enrollments/", {
      params: {
        course: id,
        page: page,
        page_size: pageSize,
        search: query || "",
        sort_field: sortField || "student__name",
        sort_direction: sortOrder || "asc",
      },
    });
  },
  getNewEnrollmentsByCourseId: async (
    courseId: string,
    instructionPeriodId: string,
    page: number,
    pageSize: number,
    query?: string,
    sortField?: string,
    sortOrder?: string
  ) => {
    return ApiWithToken.get(aulaService.uri + "new_enrollments/", {
      params: {
        course: courseId,
        instruction_period: instructionPeriodId,
        page: page,
        page_size: pageSize,
        search: query || "",
        sort_field: sortField || "student__name",
        sort_direction: sortOrder || "asc",
      },
    });
  },
  getAvailableCourseById: async (id: number) =>
    ApiWithToken.get(`${aulaService.uri}available/?course=${id}`),
  unenroll: async (studentId: number, courseId: number) =>
    ApiWithToken.delete(`${aulaService.uri}unenroll/`, {
      params: { student: studentId, course: courseId },
    }),
  newUnenroll: async (studentId: number, courseId: string, instructionPeriodId: string) =>
    ApiWithToken.delete(`${aulaService.uri}new_unenroll/`, {
      params: { student: studentId, course: courseId, instruction_period: instructionPeriodId },
    }),
  enroll: async (
    name: string,
    email: string,
    documentId: string,
    job: string,
    area: string,
    workplace: string,
    courseId: string
  ) =>
    ApiWithToken.post(`${aulaService.uri}enroll/`, {
      name: name,
      email: email,
      document_id: documentId,
      job: job,
      area: area,
      workplace: workplace,
      course: courseId,
    }),
  newEnroll: async (
    firstName: string,
    lastName: string,
    email: string,
    documentId: string,
    job: string,
    area: string,
    workplace: string,
    courseId: string,
    instructionPeriodId: string
  ) =>
    ApiWithToken.post(`${aulaService.uri}new_enroll/`, {
      first_name: firstName,
      last_name: lastName,
      email: email,
      document_id: documentId,
      job: job,
      area: area,
      workplace: workplace,
      course: courseId,
      instruction_period: instructionPeriodId,
    }),
  getAulaStudentsPdf: async (courseId: string) =>
    ApiWithToken.get(`${aulaService.uri}course_students/${courseId}/`, {
      responseType: "blob",
    }),
  getAulaNewStudentsPdf: async (courseId: string) =>
    ApiWithToken.get(`${aulaService.uri}new_course_students/${courseId}/`, {
      responseType: "blob",
    }),
  getCertificateData: async (courseId: string) =>
    ApiWithToken.get(`${aulaService.uri}course_certificate/${courseId}/`),
  getNewCertificateData: async (courseId: string, instructionPeriodId: string) =>
    ApiWithToken.get(`${aulaService.uri}new_course_certificate/${courseId}/${instructionPeriodId}/`),
  getStudentCertificateData: async (courseId: string, studentId: string, instructionPeriodId: string) =>
    ApiWithToken.get(
      `${aulaService.uri}new_student_certificate/`, {
        params: { course_id: courseId, student_id: studentId, instruction_period_id: instructionPeriodId },
      }
    ),
  putStudent: async (
    id: number,
    name: string,
    email: string,
    documentId: string,
    job: string,
    area: string,
    workplace: string
  ) =>
    ApiWithToken.put(`${aulaService.uri}student/?id=${id}`, {
      name: name,
      email: email,
      document_id: documentId,
      job: job,
      area: area,
      workplace: workplace,
    }),
  putNewStudent: async (
    id: number,
    firstName: string,
    lastName: string,
    email: string,
    documentId: string,
    job: string,
    area: string,
    workplace: string
  ) =>
    ApiWithToken.put(`${aulaService.uri}new_student/?id=${id}`, {
      first_name: firstName,
      last_name: lastName,
      email: email,
      document_id: documentId,
      job: job,
      area: area,
      workplace: workplace,
    }),
  getStudentByEmail: async (email: string) =>
    ApiWithToken.get(`${aulaService.uri}student/?email=${email}`),
  markCompletedLectures: async (courseId: number, studentId: number, newStudentId: number) =>
    ApiWithToken.put(`${aulaService.uri}mark_completed/`, {
      course: courseId,
      student: studentId,
      new_student: newStudentId,
    }),
};
