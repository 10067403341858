import React, {useEffect, useState} from "react";
import { GenderLabAppShell, GenderLabBreadcrumbs, GenderLabLoader } from "../../../components";
import styles from './ReporteAnaliticasPage.module.css';
import { useAuth } from "../../../context";
import {Button, Flex, Paper, Stack, Grid, Col} from "@mantine/core";
import Chart from "react-apexcharts";
import {complaintsService} from "../../../api/services/complaints-service";

interface ChartData {
  options: {
    chart: {
      id: string;
    };
    xaxis: {
      categories: string[];
    };
  };
  series: {
    name: string;
    data: number[];
  }[];
}

export const ReporteAnaliticasPage: React.FC = () => {
  const { user } = useAuth();
  const [chartData, setChartData] = useState<ChartData>({
    options: {
      chart: {
        id: "kind-bar-chart",
      },
      xaxis: {
        categories: [],
      },
    },
    series: [
      {
        name: "Tipo de Reporte",
        data: [],
      },
    ],
  })
  const [activeButton, setActiveButton] = useState<string>('¿Qué relación se reporta más?')

  const getChart = async (questionId: number, chartName: string) => {
    try {
      setActiveButton(chartName);
      const data = await complaintsService.chats.getChartsByQuestionId(questionId)
      console.log('data:', data)

      const total = data.reduce((sum, item) => sum + item.value, 0);
      const labels = data.map((item) => `${item.label} (${((item.value / total) * 100).toFixed(2)}%)`);
      const values = data.map((item) => item.value);

      setChartData({
        options: {
          ...chartData.options,
          xaxis: { categories: labels },
        },
        series: [{ name: chartName, data: values }],
      });
    } catch (error) {
      console.error("Error fetching chart data:", error);
    }
  }

  useEffect(() => {
    getChart(39, 'Relación')

  }, []);

  const breadcrumbs = () => (
    <div className={styles.title}>
      <GenderLabBreadcrumbs breadcrumbs={[{ link: "", title: "Analíticas" }]} />
    </div>
  );

  const loader = () => (
    <GenderLabAppShell>
      {breadcrumbs()}
      <GenderLabLoader />
    </GenderLabAppShell>
  );

  if (!user) {
    return loader();
  }

  return (
    <GenderLabAppShell>
      {breadcrumbs()}
      <Stack mt={20}>
        <Flex justify="flex-start" align="center" direction="row" wrap="wrap" gap="md">
          <Paper withBorder p={20} radius={10} style={{ overflowX: "auto", width: "100%" }}>
            <Grid>
              <Col span={12} style={{ display: 'flex', gap: '10px' }}>
                <Button
                  onClick={() => getChart(39, 'Relación')}
                  variant={activeButton === 'Relación' ? 'filled' : 'light'}
                >
                  ¿Qué relación se reporta más?
                </Button>
                <Button
                  onClick={() => getChart(31, 'Frecuencia')}
                  variant={activeButton === 'Frecuencia' ? 'filled' : 'light'}
                >
                  ¿Qué reporte es más frecuente?
                </Button>
              </Col>
              <Col span={12}>
                <Chart
                  options={chartData.options}
                  series={chartData.series}
                  type="bar"
                  width="100%"
                />
              </Col>
            </Grid>
          </Paper>
        </Flex>
      </Stack>
    </GenderLabAppShell>
  );
};
