import {
  Button,
  Card,
  Center,
  Divider,
  Flex,
  Group,
  Loader,
  Modal,
  Pagination,
  RingProgress,
  Stack,
  Table,
  Text,
  TextInput,
  Title,
  createStyles,
} from "@mantine/core";
import { AiOutlineDelete } from "react-icons/ai";
import { openConfirmModal } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import { useDisclosure } from "@mantine/hooks";
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import { FaSort } from "react-icons/fa";
import { useParams } from "react-router-dom";

import {
  GenderLabAppShell,
  GenderLabBreadcrumbs,
  GenderLabLoader,
  ResultadosCard,
  EditButton,
} from "../../components";
import { aulaService } from "../../api/services";
import { TActiveCourse, TActiveCourseEnrollmentPage, TActiveCourseEnrollmentStudent } from "../../types";
import { formatDate, getStudentName, validateStringField } from "../../utils";
import { useAuth } from "../../context";
import { useTranslation } from "react-i18next";
import { ZIndex } from "../../utils/zIndex";

const useStyles = createStyles((theme) => ({
  alumnosTHead: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  showOnMedium: {
    display: "none",
    "@media (max-width: 1380px)": {
      display: "block",
    },
  },
  hideOnMedium: {
    "@media (max-width: 1380px)": {
      display: "none",
    },
  },
  hideOnSmall: {
    "@media (max-width: 600px)": {
      display: "none",
    },
  },
}));

export const CursoCompradoPage = () => {
  const { t } = useTranslation();
  const form = useForm({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      document_id: "",
      workplace: "",
      area: "",
      job: "",
    },

    validate: {
      first_name: validateStringField,
      last_name: validateStringField,
      email: validateStringField,
    },
  });

  const [opened, { close, open }] = useDisclosure(false);

  const [query, setQuery] = useState("");
  const [sortField, setSortField] = useState<string | undefined>(undefined);
  const [currentSortDirection, setCurrentSortDirection] = useState<
    string | undefined
  >(undefined);

  const [loading, setLoading] = useState(false);

  const [courseData, setCourseData] = useState<TActiveCourse>(null!);
  const [enrollmentsPage, setEnrollmentsPage] =
    useState<TActiveCourseEnrollmentPage>(null!);

  const [sortDirection, setSortDirection] = useState({
    student__name: "desc",
    student__email: "desc",
    percentage_completed: "desc",
    completed_at: "desc",
    student__workplace: "desc",
    student__area: "desc",
    student__job: "desc",
    student__document_id: "desc",
  });
  const [activePage, setPage] = useState(1);

  const [courseProgress, setCourseProgress] = useState(0);
  const [selectedStudent, setSelectedStudent] = useState<TActiveCourseEnrollmentStudent | null>(null);
  const pageSize = 25;

  const { classes } = useStyles();

  const { code: courseId, period: instructionPeriodId } = useParams();

  const { user } = useAuth();

  const getEnrollmentsData = async (
    sortField?: string,
    sortDirection?: string
  ) => {
    if (courseId && instructionPeriodId) {
      const response = await aulaService.getNewEnrollmentsByCourseId(
        courseId,
        instructionPeriodId,
        activePage,
        pageSize,
        query,
        sortField,
        sortDirection
      );
      const enrollmentsPageData: TActiveCourseEnrollmentPage = response.data;
      setEnrollmentsPage(enrollmentsPageData);
    }
  };

  const handleSort = (
    sortField:
      | "student__name"
      | "student__email"
      | "percentage_completed"
      | "completed_at"
      | "student__workplace"
      | "student__area"
      | "student__job"
      | "student__document_id"
  ) => {
    setPage(1);
    const currentSortDirection = sortDirection[sortField];
    setSortField(sortField);
    setCurrentSortDirection(currentSortDirection);

    if (sortDirection[sortField] === "asc") {
      setSortDirection((prevState) => ({
        ...prevState,
        [sortField]: "desc",
      }));
    } else {
      setSortDirection((prevState) => ({
        ...prevState,
        [sortField]: "asc",
      }));
    }
    getEnrollmentsData(sortField, currentSortDirection);
  };

  useEffect(() => {
    const getPageData = async () => {
      if (courseId) {
        getEnrollmentsData(sortField, currentSortDirection);
      }
    };

    getPageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage]);

  useEffect(() => {
    const getPageData = async () => {
      setPage(1);
      if (courseId) {
        getEnrollmentsData(sortField, currentSortDirection);
      }
    };

    getPageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    const getCourseData = async () => {
      if (courseId && instructionPeriodId) {
        const response = await aulaService.getActiveNewCourseById(
          courseId,
          instructionPeriodId
        );
        const courseData: TActiveCourse = response.data;
        setCourseData(courseData);
      }
    };

    getCourseData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (courseData) {
      setCourseProgress(
        Math.round(
          (courseData.progress.completed / courseData.progress.total) * 100
        )
      );
    }
  }, [courseData]);

  const downloadStudents = async () => {
    try {
      if (courseId) {
        const response = await aulaService.getStudentExcelByCourseId(
          parseInt(courseId)
        );

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "students.xlsx");
        document.body.appendChild(link);
        link.click();

        setTimeout(() => {
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        }, 0);
      }
    } catch {
      showNotification({
        title: "Error",
        message: t("aula.couldNotDownload"),
        color: "red",
      });
    }
  };

  const addUser = async () => {
    if (!form.validate().hasErrors && courseId && instructionPeriodId) {
      setLoading(true);
      try {
        await aulaService.newEnroll(
          form.values.first_name,
          form.values.last_name,
          form.values.email,
          form.values.document_id,
          form.values.job,
          form.values.area,
          form.values.workplace,
          courseId,
          instructionPeriodId
        );

        showNotification({
          title: "Estudiante añadido",
          message: `${form.values.first_name} ha sido añadido al curso.`,
          color: "green",
        });
      } catch (error: any) {
        const errorMessage: string = error.response.data.error;
        showNotification({
          title: "Error",
          message: errorMessage || t("aula.couldNotAddStudent"),
          color: "red",
        });
      } finally {
        setLoading(false);
        close();
        form.reset();
        setPage(1);
        getEnrollmentsData();
      }
    }
  };

  const editUser = async () => {
    if (!form.validate().hasErrors && courseId && selectedStudent) {
      setLoading(true);
      try {
        await aulaService.putNewStudent(
          selectedStudent.id,
          form.values.first_name,
          form.values.last_name,
          form.values.email,
          form.values.document_id,
          form.values.job,
          form.values.area,
          form.values.workplace
        );
      } catch (error) {
        console.error(error);
        showNotification({
          title: "Error",
          message: t("aula.couldNotEditStudent"),
          color: "red",
        });
      } finally {
        setLoading(false);
        close();
        form.reset();
        setPage(1);
        getEnrollmentsData();
      }
    }
  };
  

  const buttonText = selectedStudent === null ? "Añadir" : "Guardar cambios";

  return (
    <GenderLabAppShell>
      <GenderLabBreadcrumbs
        breadcrumbs={[{ link: "/aula-virtual", title: "Aula Virtual" }]}
      />

      {!courseData || !enrollmentsPage ? (
        <GenderLabLoader />
      ) : (
        <>
          <Card py="xs" radius="md" withBorder>
            <Text fz={26} align="center">
              {courseData.course.name}
            </Text>

            <Card.Section>
              <Divider my="sm" />
            </Card.Section>

            <Flex
              justify="space-evenly"
              align="center"
              direction={{ base: "column", sm: "row" }}
              gap="xs"
              mt="md"
            >
              <Flex direction="column" align="center">
                <Text fz={24} sx={{ lineHeight: 1 }}>
                  {courseData.course.author_name}
                </Text>
                <Text fz={14} color="dimmed">
                  docente
                </Text>
              </Flex>
              <Flex direction="column" align="center">
                <Text fz={24} sx={{ lineHeight: 1 }}>
                  {formatDate(courseData.course.release_date || "2023-07-26")}
                </Text>
                <Text fz={14} color="dimmed">
                  fecha de publicación del curso
                </Text>
              </Flex>
              <Flex gap="xs" align="center">
                <Flex direction="column" align="center">
                  <Text fz={24} sx={{ lineHeight: 1 }}>
                    {courseData.progress.completed}/{courseData.progress.total}
                  </Text>
                  <Text fz={14} color="dimmed">
                    terminaron el curso
                  </Text>
                </Flex>
                <RingProgress
                  size={60}
                  thickness={6}
                  sections={[
                    {
                      value: courseProgress,
                      color: "blue",
                    },
                  ]}
                  label={
                    <Text size="sm" align="center">
                      {courseProgress}%
                    </Text>
                  }
                />
              </Flex>
            </Flex>

            <Card.Section>
              <Divider my="md" />
            </Card.Section>

            <p style={{ textAlign: "justify" }}>
              {courseData.course.description ||
                "El curso no cuenta con una descripción."}
            </p>
          </Card>

          <Modal
            opened={opened}
            onClose={() => {
              form.reset();
              close();
            }}
            title={
              <Title
                sx={{
                  fontSize: 24,
                  "@media (max-width: 1024px)": {
                    fontSize: 18,
                  },
                }}
              >
                {selectedStudent === null ? "Añadir" : "Editar"} estudiante
              </Title>
            }
            zIndex={ZIndex.modal}
          >
            <Stack spacing="md">
              <TextInput placeholder={t("myAccount.names") + "*"} {...form.getInputProps("first_name")} />
              <TextInput placeholder={t("myAccount.surnames") + "*"} {...form.getInputProps("last_name")} />
              <TextInput
                placeholder={t("login.email") + "*"}
                {...form.getInputProps("email")}
              />
              <TextInput
                placeholder={t("aula.idCard")}
                {...form.getInputProps("document_id")}
              />
              <TextInput
                placeholder={t("aula.workplace")}
                {...form.getInputProps("workplace")}
              />
              <TextInput placeholder={t("aula.area")} {...form.getInputProps("area")} />
              <TextInput placeholder={t("aula.job")} {...form.getInputProps("job")} />
              <Button onClick={selectedStudent === null ? addUser : editUser}>
                {loading ? <Loader size="xs" /> : buttonText}
              </Button>
            </Stack>
          </Modal>

          <ResultadosCard title={t("aula.students")} sx={{ marginTop: 18 }}>
            <Flex gap="md">
              <TextInput
                placeholder={t("aula.search")}
                w={450}
                value={query}
                onChange={(event) => setQuery(event.currentTarget.value)}
              />
              <Button
                color="green"
                variant="outline"
                onClick={() => {
                  setSelectedStudent(null);
                  open();
                }}
                disabled={user?.hasExpiredAULA}
              >
                + {t("aula.student")}
              </Button>
            </Flex>
            <Table
              striped
              highlightOnHover
              withBorder
              withColumnBorders
              mt="md"
            >
              <thead>
                <tr>
                  <th onClick={() => handleSort("student__name")}>
                    <div className={classes.alumnosTHead}>
                      <>{t("name")}</>
                      <FaSort />
                    </div>
                  </th>
                  <th
                    onClick={() => handleSort("student__document_id")}
                    className={classes.hideOnMedium}
                  >
                    <div className={classes.alumnosTHead}>
                      <>{t("aula.idCard")}</>
                      <FaSort />
                    </div>
                  </th>
                  <th
                    onClick={() => handleSort("student__email")}
                    className={classes.hideOnSmall}
                  >
                    <div className={classes.alumnosTHead}>
                      <>{t("login.email")}</>
                      <FaSort />
                    </div>
                  </th>
                  <th onClick={() => handleSort("percentage_completed")}>
                    <div className={classes.alumnosTHead}>
                      <>{t("aula.progress")}</>
                      <FaSort />
                    </div>
                  </th>
                  <th
                    onClick={() => handleSort("student__workplace")}
                    className={classes.hideOnMedium}
                  >
                    <div className={classes.alumnosTHead}>
                      <>{t("aula.workplace")}</>
                      <FaSort />
                    </div>
                  </th>
                  <th
                    onClick={() => handleSort("student__area")}
                    className={classes.hideOnMedium}
                  >
                    <div className={classes.alumnosTHead}>
                      <>{t("aula.area")}</>
                      <FaSort />
                    </div>
                  </th>
                  <th
                    onClick={() => handleSort("student__job")}
                    className={classes.hideOnMedium}
                  >
                    <div className={classes.alumnosTHead}>
                      <>{t("aula.job")}</>
                      <FaSort />
                    </div>
                  </th>
                  <th>{t("certificate")[0].toUpperCase() + t("certificate").slice(1)}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {enrollmentsPage.data.map((row) => (
                  <tr key={row.id}>
                    <td>{getStudentName(row.student)}</td>
                    <td className={classes.hideOnMedium}>
                      {row.student.document_id || "-"}
                    </td>
                    <td className={classes.hideOnSmall}>
                      {row.student.email}
                    </td>
                    <td>
                      {row.percentage_completed
                        ? Math.round(row.percentage_completed)
                        : 0}
                      %
                    </td>
                    <td className={classes.hideOnMedium}>
                      {row.student.workplace || "-"}
                    </td>
                    <td className={classes.hideOnMedium}>
                      {row.student.area || "-"}
                    </td>
                    <td className={classes.hideOnMedium}>
                      {row.student.job || "-"}
                    </td>
                    <td>
                      <Center>
                        <Button
                          compact
                          variant="outline"
                          color="blue"
                          disabled={row.percentage_completed < 100}
                          onClick={() => {
                            if (instructionPeriodId) {
                              window.open(
                                `${window.location.origin}/certificado-alumno-aula/${row.student.id}/${courseData.course.id}/${instructionPeriodId}`,
                                "_blank"
                              );
                            }
                          }}
                        >
                          {t("view")}
                        </Button>
                      </Center>
                    </td>
                    <td>
                      <Center>
                        <EditButton
                          disabled={user?.hasExpiredAULA}
                          onClick={() => {
                            form.setValues({
                              first_name: row.student.first_name,
                              last_name: row.student.last_name,
                              email: row.student.email,
                              document_id: row.student.document_id,
                              workplace: row.student.workplace,
                              area: row.student.area,
                              job: row.student.job,
                            });
                            setSelectedStudent(row.student);
                            open();
                          }}
                        />
                      </Center>
                      <Center>
                        <Button
                          disabled={loading || user?.hasExpiredAULA}
                          compact
                          onClick={() => {
                            try {
                              openConfirmModal({
                                title: `${t("actions.delete")} ${getStudentName(row.student)} ${t("fromCourse")}`,
                                labels: {
                                  cancel: t("actions.cancel"),
                                  confirm: t("actions.delete"),
                                },
                                onConfirm: async () => {
                                  setLoading(true);
                                  if (courseId && instructionPeriodId) {
                                    await aulaService.newUnenroll(
                                      row.student.id,
                                      courseId,
                                      instructionPeriodId
                                    );
                                  }
                                  setPage(1);
                                  getEnrollmentsData();
                                  showNotification({
                                    title: t("aula.studentDeleted"),
                                    message: `${getStudentName(row.student)} ${t("hasBeenDeletedFromCourse")}.`,
                                    color: "green",
                                  });
                                  setEnrollmentsPage((prevState) => ({
                                    ...prevState,
                                    data: prevState.data.filter(
                                      (enrollment) =>
                                        enrollment.student.id !== row.student.id
                                    ),
                                  }));
                                  setLoading(false);
                                },
                              });
                            } catch {
                              showNotification({
                                title: "Error",
                                message: t("aula.couldNotDeleteStudent"),
                                color: "red",
                              });
                            }
                          }}
                        >
                          <AiOutlineDelete />
                        </Button>
                      </Center>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Center>
              <Pagination
                mt="md"
                total={enrollmentsPage.total_pages}
                onChange={setPage}
                color="blue"
                page={activePage}
              />
            </Center>
          </ResultadosCard>

          <Group mt="md">
            <Button
              component="a"
              target="_blank"
              rel="noopener noreferrer"
              href={courseData.course.syllabus_url}
              size="sm"
              color="blue"
              disabled={!courseData.course.syllabus_url}
            >
              {t("aula.viewSyllabus")}
            </Button>
            <Button color="blue" onClick={downloadStudents}>
              {t("actions.download")} {t("report")}
            </Button>
          </Group>
        </>
      )}
    </GenderLabAppShell>
  );
};
