import React, { useState } from "react"
import { ActionIcon, Button, Col, Grid, Group, Paper, Select, Table, Text, Title } from "@mantine/core"
import { IconArrowLeft, IconTrash } from "@tabler/icons-react"
import { MChat } from "../../types/Complaint"
import { IconCheck } from "@tabler/icons"
import { complaintsService } from "../../api/services/complaints-service";

interface CDDetailsProps {
  setShowDetails: (showDetails: boolean) => void
  selectedChat: MChat | undefined
  formatDate: (date: string) => string
  setSelectedChat: (chat: MChat | undefined) => void
}

export const CDDetails: React.FC<CDDetailsProps> = ({ setShowDetails, selectedChat, formatDate, setSelectedChat }) => {
  const [isEditing, setIsEditing] = useState(false)
  const [selectedValue, setSelectedValue] = useState<string | null>(selectedChat ? selectedChat.state : null)
  const [loading, setLoading] = useState(false)

  const handleCancel = () => {
    setIsEditing(false)
    setSelectedValue(null)
  };

  const handleUpdate = async () => {
    setIsEditing(false)
    setLoading(true)

    try {
      if (selectedChat && selectedValue) {
        const updatedChat = await complaintsService.chats.updateChatState(selectedChat.id, selectedValue)
        setSelectedChat(updatedChat)
        console.log("Chat updated successfully:", updatedChat);
      }
    } catch (error) {
      console.error("Failed to update chat state:", error);
    } finally {
      setLoading(false);
    }
  }

  const convertString = (text: string) => {
    switch (text) {
      case 'Report':
        return 'Reporte'
    }
  }

  return (
    <>
      <Group position="left" align="center" noWrap>
        <ActionIcon onClick={() => { setShowDetails(false) }}>
          <IconArrowLeft />
        </ActionIcon>
        <div>
          <Title order={3} style={{ wordBreak: 'break-word' }}>Caso: {selectedChat?.code}</Title>
          <Title order={4} style={{ wordBreak: 'break-word' }}>Información y seguimiento del caso</Title>
        </div>
      </Group>

      <Paper withBorder p={20} radius={10} mt={20} style={{ minWidth: 300, textAlign: "center" }}>
        <Group position="apart" grow>
          <Grid gutter="md" align="stretch">
            <Col xs={12} sm={8} md={9} lg={10}>
              <div style={{ overflowX: 'auto' }}>
                <Table striped highlightOnHover>
                  <thead>
                  <tr>
                    <th>Tipo</th>
                    <th>Estado</th>
                    <th>Fecha denuncia</th>
                    <th>Última Actualización</th>
                    <th>Responsable</th>
                    <th>Quién reporta</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>{selectedChat ? convertString(selectedChat.kind) : ''}</td>
                    <td>{selectedChat?.state}</td>
                    <td>{formatDate(selectedChat ? selectedChat.created_at : "")}</td>
                    <td>{formatDate(selectedChat ? selectedChat.updated_at : "")}</td>
                    <td>Carlos Ortiz</td>
                    <td>Testigo</td>
                  </tr>
                  </tbody>
                </Table>
              </div>
            </Col>

            <Col xs={12} sm={4} md={3} lg={2}>
              <Group position="center" mb="md">
                {!isEditing ? (
                  <>
                    <Text color="dimmed">{selectedChat?.state || "No State"}</Text>
                    <Button variant="outline" color="blue" onClick={() => setIsEditing(true)} fullWidth>
                      Gestionar caso
                    </Button>
                  </>
                ) : (
                  <>
                    <Select
                      data={["Recibido", "En investigación", "Información solicitada", "Información recibida", "Pendiente", "Cerrado"]}
                      value={selectedValue}
                      onChange={setSelectedValue}
                      placeholder="Select state"
                      sx={{ width: '100%' }}
                    />
                    <Group position="center" grow mt="md">
                      <ActionIcon onClick={handleCancel}>
                        <IconTrash size={18} />
                      </ActionIcon>
                      <ActionIcon onClick={handleUpdate} color="green" disabled={loading}>
                        <IconCheck size={18} />
                      </ActionIcon>
                    </Group>
                  </>
                )}
              </Group>
            </Col>
          </Grid>
        </Group>
      </Paper>

      <Title order={4} mt={20}>Información sobre el caso</Title>

      <Paper withBorder p={20} radius={10} mt={20} style={{ minWidth: 300 }}>
        <Table striped highlightOnHover>
          <thead>
          <tr>
            <th>Rol</th>
            <th>Mensaje</th>
          </tr>
          </thead>
          <tbody>
          {selectedChat?.messages.map(message => {
            let msg = ''
            if (message.role === "user") {
              msg = message.content as string
            } else {
              if (typeof message.content !== 'string' && message.content?.answer) {
                msg = message.content.answer;
              }
            }
            return (
              <tr key={message.id}>
                <td>{message?.role}</td>
                <td><Text style={{ whiteSpace: 'pre-wrap', fontSize: '16px' }}>{msg}</Text></td>
              </tr>
            )
          })}
          </tbody>
        </Table>
      </Paper>
    </>
  )
}
